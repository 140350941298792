.projects {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.87;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
}

.project-discr {
  position: absolute;
  left: 0;
  top: 100%;

  width: 100%;
  height: 100%;
  padding: 20px 15px;
  background-color: $projectDiscrBgColor;

  font-family: $projectDescrFont;
  color: $accent-color;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.36;

  @media screen and (min-width: 400px) {
    padding: 50px 24px;
    font-size: 18px;
    line-height: 1.56;
  }

  @media screen and (min-width: 480px) {
    padding: 49px 40px;
  }

  @media screen and (min-width: 768px) {
    padding: 45px 20px;
  }

  @media screen and (min-width: 1200px) {
    padding: 50px 24px;
  }

  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 250ms;
}

.projects-box {
    width: 100%;
border-radius: $projectBorderRadius;
    @media screen and (min-width: 768px) {
        width: calc((100% - 30px) / 2);
        margin-bottom: 30px;
    }


  @media screen and (min-width: 1200px) {
    width: calc((100% - 60px) / 3);
  }

  transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);

  @media screen and (max-width: 767.98px) {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1199.98px) {
    &:not(:nth-child(2n)) {
      margin-right: 30px;
    }

    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: 1200px) {
    &:not(:nth-child(3n)) {
      margin-right: 30px;
    }

    &:nth-last-child(-n + 3) {
      margin-bottom: 0;
    }
  }

  &:focus .project-discr,
  &:hover .project-discr {
    transform: translateY(-100%);
  }

  &:hover,
  &:focus {
    box-shadow: 0px 1px 1px $shadowColor-12, 0px 4px 4px $shadowColor-6,
      1px 4px 6px $shadowColor-16;
  }
}

.project-pict {
    position: relative;
    overflow: hidden;
    border-radius: $projectBorderRadius $projectBorderRadius 0 0;
}

.projects-description {
    flex-grow: 1;
    padding: 20px 24px;
    border-radius: 0 0 $projectBorderRadius $projectBorderRadius;
    border-bottom: 1px solid $discriptionBorderColor;
    border-left: 1px solid $discriptionBorderColor;
    border-right: 1px solid $discriptionBorderColor;
}

.project-title {
  margin-bottom: 4px;

  color: $title-text-colot;
  font-weight: 700;
  font-size: 18px;
  line-height: 2;
  letter-spacing: 0.06em;
}

.projects-link {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-decoration: none;
  color: $primary-text-color;
}
