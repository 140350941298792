/* Постійні клієнти */
.regular-customers__title {
  margin-bottom: 30px;

  font-weight: 700;
  font-size: 28px;
  line-height: 1.17;
  text-align: center;
  color: $title-text-colot;

  @media screen and (min-width: 1200px) {
    margin-bottom: 50px;
    font-size: 36px;
  }
}

.regular-customers__list {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
  height: max-content;
}

.regular-customers__item {
  width: calc((100% - 60px) / 2);
  margin: 15px;
  height: 92px;
  perspective: 600px;
  transition: transform 1000ms cubic-bezier(0.4, 0, 0.2, 1);

  @media screen and (min-width: 768px) {
    width: calc((100% - 90px) / 3);
  }

  @media screen and (min-width: 1200px) {
    width: calc((100% - 180px) / 6);
  }
}

.regular-customers__link {
  @extend %centeredFlex;
  border: 1px solid $teamSocialFill;
  border-radius: 20px;
  fill: $teamSocialFill;
  padding: 16px 0;
  cursor: pointer;

  transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1),
    border 250ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 1000ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    border: 1px solid $accent-color;
    fill: $accent-color;
  }
}

.technology__descr {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.17;
  letter-spacing: 0.03em;
  text-align: center;
}

.technology__descr--bold {
  font-weight: 700;
}

.card {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.card__face {
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
}

.card__face {
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
}

.card.is-flipped {
  transform: rotateY(180deg);
}

.card__face--back {
  padding: 10px;
  transform: rotateY(180deg);
}
