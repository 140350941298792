.specifics {
    @media screen and (min-width: 1200px) {
        padding-bottom: 0;
    }
}

.specifics__list {
    @media screen and (min-width: 768px) and (max-width: 1199.98px) {
        display: flex;
        flex-wrap: wrap;
        margin: -15px;
    }
    @media screen and (min-width: 1200px) {
        display: flex;
    }
}

.specifics__hidden-title {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
}

.specifics__item {
    width: 100%;
    
    @media screen and (max-width: 767.98px) {
         &:not(:last-child) {
        margin-bottom: 30px;
    }
    }
   

    @media screen and (min-width: 768px) and (max-width: 1199.98px) {
        width: calc((100% - 60px)/2);
        margin: 15px;
    }
    @media screen and (min-width: 1200px) {
        width: calc((100% - 90px) / 4);
         &:not(:last-child) {
        margin-right: 30px;
    }
    }
}

.specifics__logo {
    @extend %centeredFlex;
    height: 120px;
    background-color: $secondary-bg-color;
   border-radius: $projectBorderRadius;
    margin-bottom: 30px;
    background-repeat: no-repeat;
    background-position: center;
}

.specifics__title {
    margin-bottom: 10px;

    font-weight: 700;
    font-size: 14px;
    line-height: 1.14;
    color: $title-text-colot;
    text-transform: uppercase;
    text-align: center;
}
.specifics__text{
    
    padding-left: 16px;
}