$title-text-colot: #212121;
$primary-text-color: #757575;
$accent-color: rgb(255, 118, 1);
$address-link-color: rgba(255, 255, 255, 0.6);
$primary-bg-color: #2f303a;
$secondary-bg-color: #f5f4fa;
$body-color: #ffffff;
$primapyFont: 'roboto', sans-serif;
$headerLine: #ececec;
$colorBlack: #000000;
$secondaryFont: 'Raleway', sans-serif;
$logoFont: Rambla, sans-serif;
$projectDescrFont: Righteous, sans-serif;
$colorWhite: #ffffff;
$socialBgColor: rgba(255, 255, 255, 0.1);
$borderColor: rgba(255, 255, 255, 0.3);
$shadowColor-15: rgba(0, 0, 0, 0.15);
$buttonHover: #ff7801e0;
$heroBgColor: rgba(47, 48, 58, 0.4);
$activityLabelBg: rgba(47, 48, 58, 0.8);
$teamSocialFill: #afb1b8;
$shadowColor-12: rgba(0, 0, 0, 0.12);
$shadowColor-14: rgba(0, 0, 0, 0.14);
$shadowColor-20: rgba(0, 0, 0, 0.2);
$commentColor: rgba(117, 117, 117, 0.5);
$modalBorderColor: rgba(33, 33, 33, 0.2);
$backdropBgColor: rgba(0, 0, 0, 0.5);
$shadowColor-8: rgba(0, 0, 0, 0.08);
$shadowColor-10: rgba(0, 0, 0, 0.1);
$shadowColor-6: rgba(0, 0, 0, 0.06);
$shadowColor-16: rgba(0, 0, 0, 0.16);
$discriptionBorderColor: #eeeeee;
$projectDiscrBgColor: rgba(255, 241, 229, 0.9);
$projectBorderRadius: 20px;
$sliderDotsColor: rgba(255, 118, 1, 0.5);
