.menu-toggle {
  position: absolute;
  top: -6px;
  right: 10px;
  display: block;
  cursor: pointer;

  width: 40px;
  height: 40px;
  border: 0;
  padding: 0;
  background-color: transparent;

  transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    fill: $accent-color;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.js-close-menu {
  position: absolute;
  right: 10px;
  top: 10px;
  @media screen and (min-width: 480px) {
    right: calc((100% - 460px) / 2);
  }
}

.menu-container {
  position: fixed;
  left: 0;
  top: 0;
  line-height: 1.18;
  overflow-y: scroll;

  width: 100vw;
  height: 100vh;

  background-color: $body-color;
  z-index: 999;
  padding: 48px 0;
  transform: translateY(-150%);

  transition: transform 800ms cubic-bezier(0.4, 0, 0.2, 1);

  &.is-open {
    transform: translateY(0);
  }

  &__list {
    margin-bottom: 40px;
  }

  &__item:not(:last-child) {
    margin-bottom: 32px;
  }

  &__link {
    font-size: 30px;

    @media screen and (min-width: 480px) {
      font-size: 40px;
    }

    text-decoration: none;
    color: $title-text-colot;

    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover,
    &:focus {
      color: $accent-color;
    }
  }
}

.menu-container__title {
  &--accent {
    color: $accent-color;
  }
}

.mobile-nav {
  // position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(95vh - 96px);
  // max-width: 450px;
  padding: 0 15px;
  margin: 0 auto;
  width: 320px;

  @media screen and (min-width: 480px) {
    width: 400px;
  }
}

.menu-contact {
  margin-bottom: 64px;

  &__item:not(:last-child) {
    margin-bottom: 32px;
  }

  &__tel {
    text-decoration: none;
    color: $accent-color;
    font-size: 30px;
    @media screen and (min-width: 480px) {
      font-size: 40px;
    }
  }

  &__mail {
    text-decoration: none;
    color: $primary-text-color;
    font-size: 20px;
    @media screen and (min-width: 480px) {
      font-size: 24px;
    }
  }
}

.menu-social {
  display: flex;
  justify-content: space-between;

  &__item {
    font-size: 18px;

    @media screen and (min-width: 480px) {
      font-size: 22px;
    }
    color: rgba(33, 33, 33, 0.2);
  }

  &__link {
    text-decoration: none;
    color: $accent-color;
    font-size: 16px;
    line-height: 1.22;
    @media screen and (min-width: 480px) {
      font-size: 18px;
    }
  }
}
