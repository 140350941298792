.activity__list {
  display: flex;
}

.activity__title {
  margin-bottom: 50px;

  font-weight: 700;
  font-size: 36px;
  line-height: 1.17;
  text-align: center;
  color: $title-text-colot;
}

.activity {
  display: none;
  @media screen and (min-width: 1200px) {
    display: flex;
  }
}

.activity__item {
  position: relative;
  width: calc((100% - 60px) / 3);

  &:not(:last-child) {
    margin-right: 30px;
  }
}


.activity__label {
  @extend %centeredFlex;

  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;

  background-color: $activityLabelBg;

  text-transform: uppercase;
  color: $colorWhite;
  font-weight: 700;
  line-height: 1.14;
  text-align: center;

}