.footer {
  padding: 60px 0;
  background-color: $primary-bg-color;

  color: $body-color;
}

.footer-box {
  @media screen and (min-width: 768px) and (max-width: 1199.98px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: baseline;
    // padding: 0 61px;
    margin: 0 auto;
  }
  @media screen and (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.footer__logo {
  display: block;
  text-align: center;
  margin-bottom: 20px;

  color: $colorWhite;
  text-decoration: none;
  font-family: $logoFont;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.17;

  @media screen and (min-width: 1200px) {
    font-size: 26px;
    line-height: 1.19;
  }
}

.footer__logo-item {
  color: $accent-color;
}
@media screen and (max-width: 1199.98px) {
  .address-box {
    margin-bottom: 60px;
  }
}

.address {
  font-style: normal;
  text-align: center;

  @media screen and (min-width: 1200px) {
    text-align: start;
  }
}

.address__link {
  display: block;
  margin-top: 5px;

  text-decoration: none;
  color: $address-link-color;

  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    color: $accent-color;
    fill: $accent-color;
  }
}

.social {
  @media screen and (max-width: 767.98px) {
    margin-bottom: 60px;
  }
}

.social__title,
.subscription__title {
  margin-bottom: 20px;

  color: $colorWhite;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.14;
  text-transform: uppercase;
  @media screen and (max-width: 1199.98px) {
    text-align: center;
  }
}

.social__list {
  display: flex;
  justify-content: center;
}

.social__item:not(:last-child) {
  margin-right: 10px;
}

.social a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: $socialBgColor;
  fill: $colorWhite;

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    background-color: $accent-color;
  }
}

.subscription {
  @media screen and (min-width: 768px) and (max-width: 1199.98px) {
    margin: 0 auto;
    // padding: 0 100px;
  }
  @media screen and (min-width: 1200px) {
    // margin-left: 93px;
  }
}

.subscription__input {
  @media screen and (min-width: 1200px) {
    display: flex;
  }

  & input {
    margin-bottom: 20px;
    width: 100%;
    height: 50px;
    padding: 15px 16px;
    border: 1px solid $borderColor;
    filter: drop-shadow(0px 4px 4px $shadowColor-15);
    border-radius: 4px;
    background-color: $primary-bg-color;
    outline: none;

    font-size: 16px;
    line-height: 1.25;
    color: $address-link-color;

    @media screen and (min-width: 480px) {
      width: 450px;
    }
    @media screen and (min-width: 1200px) {
      width: 358px;
      margin-bottom: 0;
    }

    transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  & input:focus {
    border-color: $accent-color;
  }

  & input::placeholder {
    font-size: 16px;
    line-height: 1.25;
    color: $address-link-color;
  }
}

.subscription__btn {
  padding: 10px 0px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  text-decoration: none;
  background-color: $accent-color;
  box-shadow: 0px 4px 4px $shadowColor-15;
  border-radius: $projectBorderRadius;
  border: none;

  font-weight: 700;
  font-size: 16px;
  line-height: 1.88;
  letter-spacing: 0.06em;
  color: $colorWhite;

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    background: $buttonHover;
  }
}

.subscription__icon {
  margin-left: 10px;
}
