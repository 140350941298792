.backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    padding: 93px 15px;
    background-color: rgba($backdropBgColor, 0.4);
    backdrop-filter: blur(5px);
    overflow-y: scroll;
    transition: opacity 700ms cubic-bezier(0.4, 0, 0.2, 1), transform 700ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal {
    position: relative;
    width: 100%;
    max-width: 450px;
    border-radius: $projectBorderRadius;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $body-color;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); 
    transform-origin: top center;
    transition: transform 700ms cubic-bezier(0.4, 0, 0.2, 1), opacity 250ms cubic-bezier(0.2, 0, 0.2, 0.5);

    @media screen and (min-width: 482px) {
        margin: 0 auto;
    }
    @media screen and (min-width: 768px) {
        width: 450px;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(1);
    }
    @media screen and (min-width: 1200px) {
        max-width: 528px;
    }
}

.is-hidden {
    opacity: 0;
    pointer-events: none;
}

.backdrop.is-hidden .modal {
    transform: translate(-50%, -50%) scaleY(0);
}

.close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    overflow: hidden;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: transparent; 
    color: transparent;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.close-modal {
    border: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    fill: $colorBlack;
    transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover,
    &:focus {
        fill: $accent-color;
    }
}

.modal-form {
    display: flex;
    flex-direction: column;
    padding: 40px;
    transition: transform 700ms cubic-bezier(0.4, 0, 0.2, 1), opacity 250ms cubic-bezier(0.2, 0, 0.2, 0.5);

    & b {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        color: $title-text-colot;

        @media screen and (min-width: 453px) {
            font-size: 20px;
            line-height: 1.5;
        }

        @media screen and (min-width: 1200px) {
            line-height: 1.15;
        }
    }
}

.modal-form .comment-area {
    resize: none;
    padding: 12px 16px;
    border: 1px solid $modalBorderColor;
    border-radius: 4px;
    background-color: $body-color;
    color: $primary-text-color;
    font-size: 14px;
    line-height: 1.14;
    outline: none;
    transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

    &:focus {
        border-color: $accent-color;
    }
}

.user-name label,
.user-email label,
.phone-number label,
.comment label {
    margin-bottom: 4px;
    padding-left: 16px;
}

.form-input {
    height: 40px;
    padding-left: 42px;
    border: 1px solid $modalBorderColor;
    border-radius: $projectBorderRadius;
    outline: none;
    font-size: 16px;
    color: $primary-text-color;
    transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

    &:focus {
        border-color: $accent-color;
    }
    
    &:focus + .form-icon {
        fill: $accent-color;
    }
}

.user-name,
.phone-number,
.user-email,
.comment {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.01em;
}

.form-icon {
    position: absolute;
    left: 12px;
    bottom: 11px;
    fill: $colorBlack;
    transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.comment {
    margin-bottom: 20px;

    & textarea::placeholder {
        letter-spacing: 0.01em;
        color: $commentColor;
    }
}

.license {
    position: relative;
    @extend %centeredFlex;
    margin-bottom: 30px;
    font-size: 7px;
    line-height: 1.17;
    letter-spacing: 0;

    @media screen and (min-width: 360px) {
        font-size: 8px;
    }
    @media screen and (min-width: 393px) {
        font-size: 10px;
    }
    @media screen and (min-width: 445px) {
        font-size: 12px;
    }
    @media screen and (min-width: 463px) {
        letter-spacing: 0.03em;
    }
}

.checkbox-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);

    &:checked + .checkbox {
        background-image: url(../images/svg/icon-check.svg);
        background-size: contain;
        background-origin: border-box;
        background-color: $accent-color;
        border-color: transparent;
    }
}

.checkbox {
    width: 16px;
    height: 15px;
    margin-right: 8px;
    border: 2px solid $title-text-colot;
    border-radius: 3px;
}

.license a {
    margin-left: 4px;
    color: $accent-color;
}

.modal-form.submitting {
    transform: scaleY(0);
    opacity: 0;
}

.modal-form button[type="submit"] {
    @extend %centeredFlex;
    width: 200px;
    height: 50px;
    margin-right: auto;
    margin-left: auto;
    border-radius: $projectBorderRadius;
    background-color: $accent-color;
    box-shadow: 0px 4px 4px $shadowColor-15;
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.88;
    letter-spacing: 0.06em;
    color: $body-color;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1);

    &:active {
        transform: scale(0.95);
    }
    &:hover,
    &:focus {
        background: $colorWhite;
        color: $accent-color;
        box-shadow: 0px 4px 4px $shadowColor-15;
        border: 1px solid $accent-color;
    } 
}

.license-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    input[type="checkbox"] {
        display: none;
    }

    label {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #333333;

        span {
            display: inline-block;
            width: 16px;
            height: 16px;
            border: 2px solid #333;
            border-radius: 50%;
            margin-right: 10px;
            background-color: transparent;
            transition: background-color 150ms 200ms, transform 300ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
            position: relative; 
            transform: scale(1); 

            &::before,
            &::after {
                content: "";
                position: absolute;
                height: 2px;
                border-radius: 2px;
                background-color: $accent-color;
                transition: width 50ms ease;
                opacity: 0;
            }

            &::before {
                top: 7px;
                left: 4px; 
                transform: rotate(45deg);
                transform-origin: 0% 0%;
            }

            &::after {
                top: 10px;
                left: 4px; 
                transform: rotate(305deg);
                transform-origin: 0% 0%;
            }
        }
    }

    input[type="checkbox"]:checked + label span {
        border-color: $accent-color;
        transform: scale(1.2); 

        &::before,
        &::after {
            opacity: 1;
        }

        &::before {
            width: 5px;
        }

        &::after {
            width: 10px;
        }
    }

    .license {
        display: flex;
        align-items: center;
        margin-left: 10px; 
    }
}
.required {
    color: red;
}