.regular-customers__link, .member-social-links a, .member-social-links, .activity__label, .specifics__logo, .contact-nav__link, .flex-head, .modal-form button[type="submit"], .license {
  justify-content: center;
  align-items: center;
  display: flex;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
}

@media screen and (min-width: 480px) {
  .container {
    width: 480px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    width: 768px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.section {
  padding: 60px 0;
}

@media screen and (min-width: 1200px) {
  .section {
    padding: 94px 0;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

body {
  color: #757575;
  letter-spacing: .03em;
  background-color: #fff;
  font-family: roboto, sans-serif;
  font-size: 14px;
  line-height: 1.71;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

.menu-toggle {
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  padding: 0;
  transition: fill .25s cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: -6px;
  right: 10px;
}

.menu-toggle:hover, .menu-toggle:focus {
  fill: #ff7601;
}

@media screen and (min-width: 768px) {
  .menu-toggle {
    display: none;
  }
}

.js-close-menu {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media screen and (min-width: 480px) {
  .js-close-menu {
    right: calc(50% - 230px);
  }
}

.menu-container {
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: #fff;
  padding: 48px 0;
  line-height: 1.18;
  transition: transform .8s cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  transform: translateY(-150%);
}

.menu-container.is-open {
  transform: translateY(0);
}

.menu-container__list {
  margin-bottom: 40px;
}

.menu-container__item:not(:last-child) {
  margin-bottom: 32px;
}

.menu-container__link {
  color: #212121;
  font-size: 30px;
  text-decoration: none;
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
}

@media screen and (min-width: 480px) {
  .menu-container__link {
    font-size: 40px;
  }
}

.menu-container__link:hover, .menu-container__link:focus, .menu-container__title--accent {
  color: #ff7601;
}

.mobile-nav {
  height: calc(95vh - 96px);
  width: 320px;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
}

@media screen and (min-width: 480px) {
  .mobile-nav {
    width: 400px;
  }
}

.menu-contact {
  margin-bottom: 64px;
}

.menu-contact__item:not(:last-child) {
  margin-bottom: 32px;
}

.menu-contact__tel {
  color: #ff7601;
  font-size: 30px;
  text-decoration: none;
}

@media screen and (min-width: 480px) {
  .menu-contact__tel {
    font-size: 40px;
  }
}

.menu-contact__mail {
  color: #757575;
  font-size: 20px;
  text-decoration: none;
}

@media screen and (min-width: 480px) {
  .menu-contact__mail {
    font-size: 24px;
  }
}

.menu-social {
  justify-content: space-between;
  display: flex;
}

.menu-social__item {
  color: rgba(33, 33, 33, .2);
  font-size: 18px;
}

@media screen and (min-width: 480px) {
  .menu-social__item {
    font-size: 22px;
  }
}

.menu-social__link {
  color: #ff7601;
  font-size: 16px;
  line-height: 1.22;
  text-decoration: none;
}

@media screen and (min-width: 480px) {
  .menu-social__link {
    font-size: 18px;
  }
}

.backdrop {
  width: 100%;
  height: 100%;
  opacity: 1;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, .4);
  padding: 93px 15px;
  transition: opacity .7s cubic-bezier(.4, 0, .2, 1), transform .7s cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.modal {
  width: 100%;
  max-width: 450px;
  transform-origin: top;
  background-color: #fff;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform .7s cubic-bezier(.4, 0, .2, 1), opacity .25s cubic-bezier(.2, 0, .2, .5);
  display: flex;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .08);
}

@media screen and (min-width: 482px) {
  .modal {
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .modal {
    width: 450px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)scale(1);
  }
}

@media screen and (min-width: 1200px) {
  .modal {
    max-width: 528px;
  }
}

.is-hidden {
  opacity: 0;
  pointer-events: none;
}

.backdrop.is-hidden .modal {
  transform: translate(-50%, -50%)scaleY(0);
}

.close-button {
  width: 40px;
  height: 40px;
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: background-color .25s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 8px;
  right: 8px;
  overflow: hidden;
}

.close-modal {
  cursor: pointer;
  fill: #000;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  padding: 0;
  transition: fill .25s cubic-bezier(.4, 0, .2, 1);
}

.close-modal:hover, .close-modal:focus {
  fill: #ff7601;
}

.modal-form {
  flex-direction: column;
  padding: 40px;
  transition: transform .7s cubic-bezier(.4, 0, .2, 1), opacity .25s cubic-bezier(.2, 0, .2, .5);
  display: flex;
}

.modal-form b {
  text-align: center;
  color: #212121;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 1.5;
}

@media screen and (min-width: 453px) {
  .modal-form b {
    font-size: 20px;
    line-height: 1.5;
  }
}

@media screen and (min-width: 1200px) {
  .modal-form b {
    line-height: 1.15;
  }
}

.modal-form .comment-area {
  resize: none;
  color: #757575;
  background-color: #fff;
  border: 1px solid rgba(33, 33, 33, .2);
  border-radius: 4px;
  outline: none;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 1.14;
  transition: border-color .25s cubic-bezier(.4, 0, .2, 1);
}

.modal-form .comment-area:focus {
  border-color: #ff7601;
}

.user-name label, .user-email label, .phone-number label, .comment label {
  margin-bottom: 4px;
  padding-left: 16px;
}

.form-input {
  height: 40px;
  color: #757575;
  border: 1px solid rgba(33, 33, 33, .2);
  border-radius: 20px;
  outline: none;
  padding-left: 42px;
  font-size: 16px;
  transition: border-color .25s cubic-bezier(.4, 0, .2, 1);
}

.form-input:focus {
  border-color: #ff7601;
}

.form-input:focus + .form-icon {
  fill: #ff7601;
}

.user-name, .phone-number, .user-email, .comment {
  letter-spacing: .01em;
  flex-direction: column;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 1.17;
  display: flex;
  position: relative;
}

.form-icon {
  fill: #000;
  transition: fill .25s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  bottom: 11px;
  left: 12px;
}

.comment {
  margin-bottom: 20px;
}

.comment textarea::-ms-input-placeholder {
  letter-spacing: .01em;
  color: rgba(117, 117, 117, .5);
}

.comment textarea::placeholder {
  letter-spacing: .01em;
  color: rgba(117, 117, 117, .5);
}

.license {
  letter-spacing: 0;
  margin-bottom: 30px;
  font-size: 7px;
  line-height: 1.17;
  position: relative;
}

@media screen and (min-width: 360px) {
  .license {
    font-size: 8px;
  }
}

@media screen and (min-width: 393px) {
  .license {
    font-size: 10px;
  }
}

@media screen and (min-width: 445px) {
  .license {
    font-size: 12px;
  }
}

@media screen and (min-width: 463px) {
  .license {
    letter-spacing: .03em;
  }
}

.checkbox-hidden {
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  position: absolute;
  overflow: hidden;
}

.checkbox-hidden:checked + .checkbox {
  background-color: #ff7601;
  background-image: url("icon-check.e383e98e.svg");
  background-size: contain;
  background-origin: border-box;
  border-color: rgba(0, 0, 0, 0);
}

.checkbox {
  width: 16px;
  height: 15px;
  border: 2px solid #212121;
  border-radius: 3px;
  margin-right: 8px;
}

.license a {
  color: #ff7601;
  margin-left: 4px;
}

.modal-form.submitting {
  opacity: 0;
  transform: scaleY(0);
}

.modal-form button[type="submit"] {
  width: 200px;
  height: 50px;
  letter-spacing: .06em;
  color: #fff;
  background-color: #ff7601;
  border: none;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.88;
  transition: background-color .3s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0 4px 4px rgba(0, 0, 0, .15);
}

.modal-form button[type="submit"]:active {
  transform: scale(.95);
}

.modal-form button[type="submit"]:hover, .modal-form button[type="submit"]:focus {
  color: #ff7601;
  background: #fff;
  border: 1px solid #ff7601;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .15);
}

.license-wrapper {
  align-items: center;
  margin-bottom: 30px;
  display: flex;
}

.license-wrapper input[type="checkbox"] {
  display: none;
}

.license-wrapper label {
  cursor: pointer;
  color: #333;
  align-items: center;
  display: flex;
  position: relative;
}

.license-wrapper label span {
  width: 16px;
  height: 16px;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #333;
  border-radius: 50%;
  margin-right: 10px;
  transition: background-color .15s .2s, transform .3s cubic-bezier(.78, -1.22, .17, 1.89);
  display: inline-block;
  position: relative;
  transform: scale(1);
}

.license-wrapper label span:before, .license-wrapper label span:after {
  content: "";
  height: 2px;
  opacity: 0;
  background-color: #ff7601;
  border-radius: 2px;
  transition: width 50ms;
  position: absolute;
}

.license-wrapper label span:before {
  transform-origin: 0 0;
  top: 7px;
  left: 4px;
  transform: rotate(45deg);
}

.license-wrapper label span:after {
  transform-origin: 0 0;
  top: 10px;
  left: 4px;
  transform: rotate(305deg);
}

.license-wrapper input[type="checkbox"]:checked + label span {
  border-color: #ff7601;
  transform: scale(1.2);
}

.license-wrapper input[type="checkbox"]:checked + label span:before, .license-wrapper input[type="checkbox"]:checked + label span:after {
  opacity: 1;
}

.license-wrapper input[type="checkbox"]:checked + label span:before {
  width: 5px;
}

.license-wrapper input[type="checkbox"]:checked + label span:after {
  width: 10px;
}

.license-wrapper .license {
  align-items: center;
  margin-left: 10px;
  display: flex;
}

.required {
  color: red;
}

.header {
  letter-spacing: .02em;
  border-bottom: 1px solid #ececec;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  position: relative;
}

@media screen and (max-width: 767.98px) {
  .header {
    height: 60px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.flex-head {
  position: relative;
}

@media screen and (max-width: 767.98px) {
  .flex-head {
    justify-content: flex-start;
  }
}

.flex-head__logo-header {
  color: #000;
  font-family: Rambla, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.17;
  text-decoration: none;
}

@media screen and (min-width: 1200px) {
  .flex-head__logo-header {
    font-size: 26px;
    line-height: 1.19;
  }
}

.flex-head__logo-item {
  color: #ff7601;
}

@media screen and (max-width: 767.98px) {
  .site-nav {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .site-nav {
    margin-left: 88px;
  }
}

@media screen and (min-width: 1200px) {
  .site-nav {
    margin-left: 93px;
  }
}

.site-nav__list {
  display: flex;
}

.site-nav__item {
  position: relative;
}

.site-nav__item:not(:last-child) {
  margin-right: 50px;
}

.site-nav__link {
  color: #212121;
  padding-top: 32px;
  padding-bottom: 32px;
  text-decoration: none;
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.site-nav__link:after {
  content: "";
  width: 100%;
  height: 4px;
  border-radius: 2px;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.site-nav__link:hover:after {
  transform: scaleX(100%);
}

.site-nav__link:hover, .site-nav__title--accent {
  color: #ff7601;
}

.site-nav__title--accent:after {
  content: "";
  width: 100%;
  height: 4px;
  background-color: #ff7601;
  border-radius: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(100%);
}

.site-nav__title--accent:hover, .site-nav__title--accent:focus {
  color: #ff7601;
}

.contact-nav {
  margin-left: auto;
  display: flex;
}

@media screen and (max-width: 1199.98px) {
  .contact-nav {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 767.98px) {
  .contact-nav {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199.98px) {
  .contact-nav__item:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1200px) {
  .contact-nav__item:not(:last-child) {
    margin-right: 50px;
  }
}

.contact-nav__link {
  color: #757575;
  fill: #757575;
  text-decoration: none;
  transition: color .25s cubic-bezier(.4, 0, .2, 1), fill .25s cubic-bezier(.4, 0, .2, 1);
}

@media screen and (max-width: 1199.98px) {
  .contact-nav__link {
    font-size: 12px;
    line-height: 1.17;
  }
}

@media screen and (min-width: 1200px) {
  .contact-nav__link {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.contact-nav__link:hover, .contact-nav__link:focus {
  color: #ff7601;
  fill: #ff7601;
}

.contact-nav__icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

@media screen and (min-width: 1200px) {
  .contact-nav__icon {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
}

.hero {
  max-width: 1600px;
  height: 400px;
  text-align: center;
  background-color: #2f303a;
  background-image: linear-gradient(to right, rgba(47, 48, 58, .4), rgba(47, 48, 58, .4)), url("hero_mobile.d61e503c.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  padding: 118px 0;
}

@media screen and (min-width: 480px) {
  .hero {
    background-image: linear-gradient(to right, rgba(47, 48, 58, .4), rgba(47, 48, 58, .4)), url("hero_tablet.2d6910bf.jpg");
  }
}

@media screen and (min-width: 768px) {
  .hero {
    background-image: linear-gradient(to right, rgba(47, 48, 58, .4), rgba(47, 48, 58, .4)), url("hero_desktop.419a610d.jpg");
  }
}

@media screen and (min-width: 1200px) {
  .hero {
    height: 600px;
    padding: 200px 0;
  }
}

@media (min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2x) {
  .hero {
    background-image: linear-gradient(to right, rgba(47, 48, 58, .4), rgba(47, 48, 58, .4)), url("hero_mobile@2x.6b256b9d.jpg");
  }
}

@media screen and (min-device-pixel-ratio: 2) and (min-width: 480px), screen and (min-resolution: 192dpi) and (min-width: 480px), screen and (min-resolution: 2x) and (min-width: 480px) {
  .hero {
    background-image: linear-gradient(to right, rgba(47, 48, 58, .4), rgba(47, 48, 58, .4)), url("hero_tablet@2x.3073d5d4.jpg");
  }
}

@media screen and (min-device-pixel-ratio: 2) and (min-width: 768px), screen and (min-resolution: 192dpi) and (min-width: 768px), screen and (min-resolution: 2x) and (min-width: 768px) {
  .hero {
    background-image: linear-gradient(to right, rgba(47, 48, 58, .4), rgba(47, 48, 58, .4)), url("hero_desktop@2x.05f62bc7.jpg");
  }
}

.hero__title {
  max-width: 360px;
  color: #fff;
  letter-spacing: .06em;
  text-transform: uppercase;
  margin: 0 auto 30px;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.62;
}

@media screen and (min-width: 360px) {
  .hero__title {
    font-size: 26px;
  }
}

@media screen and (min-width: 1200px) {
  .hero__title {
    max-width: 696px;
    font-size: 44px;
    line-height: 1.36;
  }
}

.hero__button {
  width: 200px;
  height: 50px;
  color: #fff;
  cursor: pointer;
  letter-spacing: .06em;
  background-color: #ff7601;
  border: none;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.86;
  transition: background-color .3s cubic-bezier(.4, 0, .2, 1);
  display: flex;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .15);
}

.hero__button:hover, .hero__button:focus {
  color: #ff7601;
  background: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .15);
}

@media screen and (min-width: 1200px) {
  .specifics {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199.98px) {
  .specifics__list {
    flex-wrap: wrap;
    margin: -15px;
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .specifics__list {
    display: flex;
  }
}

.specifics__hidden-title {
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  position: absolute;
  overflow: hidden;
}

.specifics__item {
  width: 100%;
}

@media screen and (max-width: 767.98px) {
  .specifics__item:not(:last-child) {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199.98px) {
  .specifics__item {
    width: calc(50% - 30px);
    margin: 15px;
  }
}

@media screen and (min-width: 1200px) {
  .specifics__item {
    width: calc(25% - 22.5px);
  }

  .specifics__item:not(:last-child) {
    margin-right: 30px;
  }
}

.specifics__logo {
  height: 120px;
  background-color: #f5f4fa;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  margin-bottom: 30px;
}

.specifics__title {
  color: #212121;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.14;
}

.specifics__text {
  padding-left: 16px;
}

.activity__list {
  display: flex;
}

.activity__title {
  text-align: center;
  color: #212121;
  margin-bottom: 50px;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.17;
}

.activity {
  display: none;
}

@media screen and (min-width: 1200px) {
  .activity {
    display: flex;
  }
}

.activity__item {
  width: calc(33.3333% - 20px);
  position: relative;
}

.activity__item:not(:last-child) {
  margin-right: 30px;
}

.activity__label {
  width: 100%;
  height: 70px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  background-color: rgba(47, 48, 58, .8);
  font-weight: 700;
  line-height: 1.14;
  position: absolute;
  bottom: 0;
  left: 0;
}

.team {
  background-color: #f5f4fa;
}

.team__title {
  text-align: center;
  color: #212121;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.17;
}

@media screen and (min-width: 1200px) {
  .team__title {
    margin-bottom: 50px;
    font-size: 36px;
  }
}

.team__list {
  width: calc(100% - 5px);
  cursor: pointer;
  height: calc(100% + 20px);
}

@media screen and (min-width: 1200px) {
  .team__list {
    height: 450px;
    display: flex;
  }
}

.team-member {
  width: calc(100% - 30px);
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  margin: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.19;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 1px rgba(0, 0, 0, .14), 0 2px 1px rgba(0, 0, 0, .2);
}

@media screen and (min-width: 768px) and (max-width: 1199.98px) {
  .team-member {
    width: calc(50% - 30px);
  }
}

@media screen and (min-width: 1200px) {
  .team-member {
    width: calc(25% - 22.5px);
  }
}

.team-member__img {
  border-radius: 20px 20px 0 0;
}

.team-member__discr {
  padding-top: 30px;
  padding-bottom: 16px;
}

.team-member__title {
  color: #212121;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.member-social-links {
  padding-bottom: 30px;
}

.member-social-links a {
  width: 44px;
  height: 44px;
  fill: #afb1b8;
  border-radius: 50%;
  transition: fill .25s cubic-bezier(.4, 0, .2, 1), background-color .25s cubic-bezier(.4, 0, .2, 1);
}

.member-social-links a:hover, .member-social-links a:focus {
  fill: #fff;
  background-color: #ff7601;
}

.member-social-links__item:not(:last-child) {
  margin-right: 10px;
}

.slick-dots > li {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 118, 1, .5);
  border-radius: 50%;
}

.slick-dots > li.slick-active {
  background-color: #ff7601;
}

.slick-dots > li > button:before {
  display: none;
}

.slick-dots {
  max-width: max-content;
  bottom: -30px;
  left: 30px;
}

.regular-customers__title {
  text-align: center;
  color: #212121;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.17;
}

@media screen and (min-width: 1200px) {
  .regular-customers__title {
    margin-bottom: 50px;
    font-size: 36px;
  }
}

.regular-customers__list {
  height: max-content;
  flex-wrap: wrap;
  margin: -15px;
  display: flex;
}

.regular-customers__item {
  width: calc(50% - 30px);
  height: 92px;
  perspective: 600px;
  margin: 15px;
  transition: transform 1s cubic-bezier(.4, 0, .2, 1);
}

@media screen and (min-width: 768px) {
  .regular-customers__item {
    width: calc(33.3333% - 30px);
  }
}

@media screen and (min-width: 1200px) {
  .regular-customers__item {
    width: calc(16.6667% - 30px);
  }
}

.regular-customers__link {
  fill: #afb1b8;
  cursor: pointer;
  border: 1px solid #afb1b8;
  border-radius: 20px;
  padding: 16px 0;
  transition: fill .25s cubic-bezier(.4, 0, .2, 1), border .25s cubic-bezier(.4, 0, .2, 1), transform 1s cubic-bezier(.4, 0, .2, 1);
}

.regular-customers__link:hover, .regular-customers__link:focus {
  fill: #ff7601;
  border: 1px solid #ff7601;
}

.technology__descr {
  letter-spacing: .03em;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.17;
}

.technology__descr--bold {
  font-weight: 700;
}

.card {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 1s;
  position: relative;
}

.card__face {
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
}

.card.is-flipped {
  transform: rotateY(180deg);
}

.card__face--back {
  padding: 10px;
  transform: rotateY(180deg);
}

.footer {
  color: #fff;
  background-color: #2f303a;
  padding: 60px 0;
}

@media screen and (min-width: 768px) and (max-width: 1199.98px) {
  .footer-box {
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: baseline;
    margin: 0 auto;
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .footer-box {
    justify-content: space-between;
    align-items: baseline;
    display: flex;
  }
}

.footer__logo {
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
  font-family: Rambla, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.17;
  text-decoration: none;
  display: block;
}

@media screen and (min-width: 1200px) {
  .footer__logo {
    font-size: 26px;
    line-height: 1.19;
  }
}

.footer__logo-item {
  color: #ff7601;
}

@media screen and (max-width: 1199.98px) {
  .address-box {
    margin-bottom: 60px;
  }
}

.address {
  text-align: center;
  font-style: normal;
}

@media screen and (min-width: 1200px) {
  .address:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: left;
  }

  .address:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: right;
  }
}

.address__link {
  color: rgba(255, 255, 255, .6);
  margin-top: 5px;
  text-decoration: none;
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.address__link:hover, .address__link:focus {
  color: #ff7601;
  fill: #ff7601;
}

@media screen and (max-width: 767.98px) {
  .social {
    margin-bottom: 60px;
  }
}

.social__title, .subscription__title {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.14;
}

@media screen and (max-width: 1199.98px) {
  .social__title, .subscription__title {
    text-align: center;
  }
}

.social__list {
  justify-content: center;
  display: flex;
}

.social__item:not(:last-child) {
  margin-right: 10px;
}

.social a {
  width: 44px;
  height: 44px;
  fill: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: background-color .25s cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.social a:hover, .social a:focus {
  background-color: #ff7601;
}

@media screen and (min-width: 768px) and (max-width: 1199.98px) {
  .subscription {
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .subscription__input {
    display: flex;
  }
}

.subscription__input input {
  width: 100%;
  height: 50px;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .15));
  color: rgba(255, 255, 255, .6);
  background-color: #2f303a;
  border: 1px solid rgba(255, 255, 255, .3);
  border-radius: 4px;
  outline: none;
  margin-bottom: 20px;
  padding: 15px 16px;
  font-size: 16px;
  line-height: 1.25;
  transition: border-color .25s cubic-bezier(.4, 0, .2, 1);
}

@media screen and (min-width: 480px) {
  .subscription__input input {
    width: 450px;
  }
}

@media screen and (min-width: 1200px) {
  .subscription__input input {
    width: 358px;
    margin-bottom: 0;
  }
}

.subscription__input input:focus {
  border-color: #ff7601;
}

.subscription__input input::-ms-input-placeholder {
  color: rgba(255, 255, 255, .6);
  font-size: 16px;
  line-height: 1.25;
}

.subscription__input input::placeholder {
  color: rgba(255, 255, 255, .6);
  font-size: 16px;
  line-height: 1.25;
}

.subscription__btn {
  width: 200px;
  height: 50px;
  letter-spacing: .06em;
  color: #fff;
  background-color: #ff7601;
  border: none;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.88;
  text-decoration: none;
  transition: background-color .25s cubic-bezier(.4, 0, .2, 1);
  display: flex;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .15);
}

.subscription__btn:hover, .subscription__btn:focus {
  background: rgba(255, 120, 1, .88);
}

.subscription__icon {
  margin-left: 10px;
}

.filters {
  max-width: 370px;
  flex-wrap: wrap;
  margin-bottom: 40px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .filters {
    max-width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .filters {
    margin-bottom: 50px;
  }
}

.filters .item:not(:last-child) {
  margin-right: 8px;
}

@media screen and (max-width: 767.98px) {
  .filters .item:not(:last-child) {
    margin-bottom: 15px;
  }
}

.filter-button {
  color: #212121;
  text-align: center;
  cursor: pointer;
  background-color: #f5f4fa;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 20px;
  padding: 6px 22px;
  font-family: roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.62;
  transition: color .25s cubic-bezier(.4, 0, .2, 1), background-color .25s cubic-bezier(.4, 0, .2, 1), box-shadow .25s cubic-bezier(.4, 0, .2, 1);
}

.filter-button:hover, .filter-button:focus, .filter-button.current {
  color: #fff;
  background-color: #ff7601;
  box-shadow: 0 3px 1px rgba(0, 0, 0, .1), 0 1px 2px rgba(0, 0, 0, .08), 0 2px 2px rgba(0, 0, 0, .12);
}

.projects {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.87;
}

@media screen and (min-width: 768px) {
  .projects {
    flex-wrap: wrap;
    display: flex;
  }
}

.project-discr {
  width: 100%;
  height: 100%;
  color: #ff7601;
  background-color: rgba(255, 241, 229, .9);
  padding: 20px 15px;
  font-family: Righteous, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.36;
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 100%;
  left: 0;
}

@media screen and (min-width: 400px) {
  .project-discr {
    padding: 50px 24px;
    font-size: 18px;
    line-height: 1.56;
  }
}

@media screen and (min-width: 480px) {
  .project-discr {
    padding: 49px 40px;
  }
}

@media screen and (min-width: 768px) {
  .project-discr {
    padding: 45px 20px;
  }
}

@media screen and (min-width: 1200px) {
  .project-discr {
    padding: 50px 24px;
  }
}

.projects-box {
  width: 100%;
  border-radius: 20px;
  transition: box-shadow .25s cubic-bezier(.4, 0, .2, 1);
}

@media screen and (min-width: 768px) {
  .projects-box {
    width: calc(50% - 15px);
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1200px) {
  .projects-box {
    width: calc(33.3333% - 20px);
  }
}

@media screen and (max-width: 767.98px) {
  .projects-box:not(:last-child) {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199.98px) {
  .projects-box:not(:nth-child(2n)) {
    margin-right: 30px;
  }

  .projects-box:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1200px) {
  .projects-box:not(:nth-child(3n)) {
    margin-right: 30px;
  }

  .projects-box:nth-last-child(-n+3) {
    margin-bottom: 0;
  }
}

.projects-box:focus .project-discr, .projects-box:hover .project-discr {
  transform: translateY(-100%);
}

.projects-box:hover, .projects-box:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, .12), 0 4px 4px rgba(0, 0, 0, .06), 1px 4px 6px rgba(0, 0, 0, .16);
}

.project-pict {
  border-radius: 20px 20px 0 0;
  position: relative;
  overflow: hidden;
}

.projects-description {
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-radius: 0 0 20px 20px;
  flex-grow: 1;
  padding: 20px 24px;
}

.project-title {
  color: #212121;
  letter-spacing: .06em;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 2;
}

.projects-link {
  height: 100%;
  color: #757575;
  flex-direction: column;
  text-decoration: none;
  display: flex;
}

/*# sourceMappingURL=index.07d4e40f.css.map */
