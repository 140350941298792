body {
    background-color: $body-color;
    color: $primary-text-color;
    font-family: $primapyFont;
    // font-style: normal;
    // font-weight: normal;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.03em;
}

img {
    display: block;
    width: 100%;
    height: auto;
}