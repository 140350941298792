/* Хедер */

.header {
  position: relative;

  border-bottom: 1px solid $headerLine;

  font-weight: 500;
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: 0.02em;
  @media screen and (max-width: 767.98px) {
    height: 60px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.flex-head {
  @extend %centeredFlex;
  position: relative;

  @media screen and (max-width: 767.98px) {
    justify-content: flex-start;
  }
}

.flex-head__logo-header {
  color: $colorBlack;
  text-decoration: none;
  font-family: $logoFont;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.17;

  @media screen and (min-width: 1200px) {
    font-size: 26px;
    line-height: 1.19;
  }
}

.flex-head__logo-item {
  color: $accent-color;
}

/* Навігація */

.site-nav {
  @media screen and (max-width: 767.98px) {
    display: none;
  }
  @media screen and (min-width: 768px) {
    margin-left: 88px;
  }

  @media screen and (min-width: 1200px) {
    margin-left: 93px;
  }
}

.site-nav__list {
  display: flex;
}

.site-nav__item {
  position: relative;

  &:not(:last-child) {
    margin-right: 50px;
  }
}

.site-nav__link {
  display: block;
  padding-top: 32px;
  padding-bottom: 32px;

  text-decoration: none;
  color: $title-text-colot;

  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;

    transform: scaleX(0);

    width: 100%;
    height: 4px;
    border-radius: 2px;

    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  &:hover::after {
    transform: scaleX(100%);
  }
  &:hover {
    color: $accent-color;
  }
}

.site-nav__title--accent {
  color: $accent-color;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;

    transform: scaleX(100%);

    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: $accent-color;
  }
  &:hover,
  &:focus {
    color: $accent-color;
  }
}

/* Контакти */
.contact-nav {
  display: flex;
  margin-left: auto;
  @media screen and (max-width: 1199.98px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media screen and (max-width: 767.98px) {
    display: none;
  }
}

.contact-nav__item:not(:last-child) {
  @media screen and (min-width: 768px) and (max-width: 1199.98px) {
    margin-bottom: 10px;
  }
  @media screen and (min-width: 1200px) {
    margin-right: 50px;
  }
}

.contact-nav__link {
  @extend %centeredFlex;
  @media screen and (max-width: 1199.98px) {
    font-size: 12px;
    line-height: 1.17;
  }
  @media screen and (min-width: 1200px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  text-decoration: none;
  color: $primary-text-color;
  fill: $primary-text-color;

  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1),
    fill 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    color: $accent-color;
    fill: $accent-color;
  }
}

.contact-nav__icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;

  @media screen and (min-width: 1200px) {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
}
