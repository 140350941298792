.hero {
    background-color: $primary-bg-color;
    background-image: linear-gradient(to right, $heroBgColor, $heroBgColor),
        url(../images/hero/hero_mobile.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-right: auto;
    margin-left: auto;
    max-width: 1600px;
    height: 400px;
    padding: 118px 0;
    text-align: center;

    @media screen and (min-width: 480px) {
        background-image: linear-gradient(to right, $heroBgColor, $heroBgColor), url(../images/hero/hero_tablet.jpg);
    }

    @media screen and (min-width: 768px) {
        background-image: linear-gradient(to right, $heroBgColor, $heroBgColor), url(../images/hero/hero_desktop.jpg);
    }

    @media screen and (min-width: 1200px) {
        height: 600px;
        padding: 200px 0;
    }

        
}

@media (min-device-pixel-ratio: 2),
(min-resolution: 192dpi),
(min-resolution: 2dppx) {
    .hero {
        background-image: linear-gradient(to right, $heroBgColor, $heroBgColor),
            url(../images/hero/hero_mobile@2x.jpg);

            @media screen and (min-width: 480px) {
                    background-image: linear-gradient(to right, $heroBgColor, $heroBgColor), url(../images/hero/hero_tablet@2x.jpg);
            }
        
            @media screen and (min-width: 768px) {
                background-image: linear-gradient(to right, $heroBgColor, $heroBgColor), url(../images/hero/hero_desktop@2x.jpg);
            }
    }
}

.hero__title {
    max-width: 360px;
    margin: 0 auto 30px;

    color: $body-color;
    font-weight: 900;
    font-size: 24px;
    line-height: 1.62;
    
    letter-spacing: 0.06em;
    text-transform: uppercase;

    @media screen and (min-width: 360px) {
        font-size: 26px;
    }

    @media screen and (min-width: 1200px) {
        max-width: 696px;
        font-size: 44px;
        line-height: 1.36;
    }
}

.hero__button {
    background-color: $accent-color;
    margin: 0 auto;
    box-shadow: 0px 4px 4px $shadowColor-15;
    border:none;
    padding: 10px 0;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $projectBorderRadius;
    color: $body-color;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.86;
    letter-spacing: 0.06em;

    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover,
    &:focus {
        background: $colorWhite;
        color: $accent-color;
        box-shadow: 0px 4px 4px $shadowColor-15;
        
       
    } 
}