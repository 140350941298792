html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}