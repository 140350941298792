/* Наша команда */
.team {
  background-color: $secondary-bg-color;
  // &-img {

  // }
}

.team__title {
  margin-bottom: 30px;

  font-weight: 700;
  font-size: 28px;
  line-height: 1.17;
  text-align: center;
  color: $title-text-colot;

  @media screen and (min-width: 1200px) {
    margin-bottom: 50px;
    font-size: 36px;
  }
}

.team__list {
  width: calc(100% - 5px);
  cursor: pointer;
  height: calc(100% + 20px);

  @media screen and (min-width: 1200px) {
    display: flex;
    height: 450px;
  }
}

.team-member {
  width: calc(100% - 30px);
  background-color: $body-color;
  box-shadow: 0px 1px 3px $shadowColor-12, 0px 1px 1px $shadowColor-14,
    0px 2px 1px $shadowColor-20;
  border-radius: $projectBorderRadius;

  font-weight: 400;
  font-size: 16px;
  line-height: 1.19;
  text-align: center;
  margin: 15px;

  @media screen and (min-width: 768px) and (max-width: 1199.98px) {
    width: calc((100% - 60px) / 2);
  }

  @media screen and (min-width: 1200px) {
    width: calc((100% - 90px) / 4);
  }
}
.team-member__img {
  border-radius: $projectBorderRadius $projectBorderRadius 0 0;
}
.team-member__discr {
  padding-top: 30px;
  padding-bottom: 16px;
}

.team-member__title {
  margin-bottom: 10px;

  color: $title-text-colot;
  font-weight: 500;
  font-size: 16px;
}

.member-social-links {
  @extend %centeredFlex;
  padding-bottom: 30px;
}

.member-social-links a {
  @extend %centeredFlex;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  fill: $teamSocialFill;

  transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    background-color: $accent-color;
    fill: $body-color;
  }
}

.member-social-links__item:not(:last-child) {
  margin-right: 10px;
}

// .slick-list {
//   height: calc(100% + 20px);

//   @media screen and (min-width: 1200px) {
//     height: 450px;
//   }
// }

.slick-dots > li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $sliderDotsColor;
}

.slick-dots > li.slick-active {
  background-color: $accent-color;
}

.slick-dots > li > button::before {
  display: none;
}

.slick-dots {
  bottom: -30;
  left: 30;
  max-width: max-content;
}
