.filters {
    display: flex;
    flex-wrap: wrap;
    max-width: 370px;
    margin-bottom: 40px;

    @media screen and (min-width: 768px) {
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 50px;
    }


    & .item:not(:last-child) {
        margin-right: 8px;

        @media screen and (max-width: 767.98px) {
           margin-bottom: 15px; 
        }
        
    }
}

.filter-button {
    background-color: $secondary-bg-color;
    border-radius: $projectBorderRadius;
    border-color: transparent;
    padding: 6px 22px;

    font-family: roboto;
    color: $title-text-colot;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.62;
    text-align: center;
    cursor: pointer;

    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1),
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1),
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);

        &:hover,
        &:focus {
            background-color: $accent-color;
            color: $body-color;
            box-shadow: 0px 3px 1px $shadowColor-10, 0px 1px 2px $shadowColor-8,
                0px 2px 2px $shadowColor-12;
        }

        &.current {
            background-color: $accent-color;
            color: $body-color;
            box-shadow: 0px 3px 1px $shadowColor-10,
                0px 1px 2px $shadowColor-8,
                0px 2px 2px $shadowColor-12;
        }
}